import React, { useState, useEffect } from 'react';
import Parser  from 'react-html-parser'
import { isSSR, removeHtmltagsInsideH2H3Tags } from './utils';

const SimpleContentPage = ({ content }) => {
    const [updatedArticleContent, setUpdatedArticleContent]=useState([]);
    // after finish load
    useEffect(()=>{
      setUpdatedArticleContent(removeHtmltagsInsideH2H3Tags(content));
    },[]);
    return (
        <>
            {/* Page Content */}
            <section>
              <div className={'container mainSpacing2 about-outr'}>
                <div className='row justify-content-center'>
                  <div className='col-md-12'>
                    <div>{Parser(isSSR() ? content : updatedArticleContent)}</div>
                  </div>
                </div>
              </div>
            </section> 
      </>
    );
};

export default SimpleContentPage;